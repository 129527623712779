.logo {
  width: 130px;
}
  
.header {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header.header-sticky {
  width: 100%;
  position: fixed;
  top: 0%;
  background-color: #fff;
  -webkit-animation: slide-down 0.7s;
  animation: slide-down 0.7s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  z-index: 999;

  @media(max-width:991px) {
    animation: none;
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.navbar.navbar-expand-lg {
  padding: 0px 0px;

  &>.container {
    @media(max-width:991px) {
      justify-content: flex-start;
    }
  }

  .navbar-toggler {
    border: 0px;
    padding: 0px;
    margin: 0px;

    &:focus {
      box-shadow: 0px 0px 0px transparent;
    }
  }

  .navbar-collapse {
    .headerMenuLinks {
      display: flex;
      align-items: center;

      @media(max-width:991px) {
        // height: calc(100vh - 85px);
        display: block;
        overflow: auto;
      }
      .dropdown{
        button{
          background: #fff;
          border: 0;
          font-weight: 500;
          font-size: 16px;
          color: #525560;
          position: relative;
          @media(max-width:991px) {
            padding-left: 0;
          }
          i{
            font-style: normal;
            color: #c51e1e;
            position: absolute;
            right: -12px;
            top: -9px;
            font-size: 12px;
          }
          &.dropdown-toggle::after{
            background: url("../../../public/images/arrowDownIcon.svg");
            border: 0 !important;
            width: 21px;
            height: 21px;
            top: 8px;
            position: absolute;
          }
        }
        .dropdown-menu.show{
          padding: 25px;
          @media(max-width:991px) {
            padding: 5px;
          }
          a{
            color: #525560;
            padding: 0 !important;
            margin-bottom: 15px;
            @media(max-width:991px) {
              font-size: 13px;
            }
            &:hover{
              background-color: none;
            }
            img{
              width: 46px;
            }
            &:nth-of-type(2){
              margin-bottom: 0;
            }
          }
        } 
      }
    }

    @media(max-width:991px) {
      width: 100%;
      min-height: 100%;
      position: fixed;
      top: 0%;
      left: -100%;
      transition: all 0.5s ease-in-out;
      background-color: rgba(0, 0, 0, 0.5);

      &.show {
        left: 0%;
      }

      .navbar-nav {
        width: 100%;
        max-width: 360px;
        height: 100%;
        background-color: var(--white);
        position: fixed;
        padding: 0px 15px;
        margin-left: 0px !important;
      }
    }

    @media(max-width:767px) {
      .navbar-nav {
        max-width: 260px;
      }
    }
  }

  .logoView,
  .logoSideMenuView {
    min-height: 85px;
    display: inline-flex;
    align-items: center;

    @media(max-width:991px) {
      min-height: 62px;
      margin-left: 20px;
    }

    .navbar-brand {
      margin-right: 0px;

      &>img {
        @media(max-width:991px) {
          max-width: 170px;
        }
      }
    }
  }

  .logoSideMenuView {
    border-bottom: 1px solid #D8D8D8;
    margin-left: 0px;

    .menuClose {
      margin: 5px 10px 0px 0px;

      .navbar-toggler-icon {
        width: 18px;
        height: 18px;
        background-image: url(../../../public/images/menu-close.svg);
      }
    }
  }

  .navbar-nav {
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--lightDark);
      border-radius: 50px;
      transition: all 0.5s ease-in-out;
      padding: 8px 15px !important;
      margin: 0px 5px;
      display: block;

      &:hover,
      &.active {

        // color: var(--blue);
        // background-color: var(--lightBlue);
        @media(max-width:991px) {
          // background-color: transparent;

          display: block;
          color: var(--blue);
        }
      }

      &.active {
        background-color: transparent;
      }

      @media(max-width:991px) {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 0px !important;
        margin: 15px 5px;
      }
    }
  }
}

#basic-navbar-nav .ctaBtn {
  padding-left: 100px;
  @media(max-width:991px){
    padding-left: 0;
  }

  a {
    background: #125485;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    padding: 12px 32px !important;
    width: 154px;
    height: 48px;
    border-radius: 4px;
    border: 0;
  }
}
