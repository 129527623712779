.FooterSec {
    background: #0B0706;
    color: #fff;
    padding: 100px 0 0 0;

    @media(max-width:991px) {
        padding: 50px 0 0 0;
    }

    .threeColumn {
        display: grid;
        grid-template-columns: 33% 30% 30%;
        gap: 35px;
        padding-bottom: 100px;

        @media(max-width:991px) {
            display: flex;
            flex-direction: column;
            padding-bottom: 25px;
        }

        span {
            display: block;
            padding-top: 10px;

            @media(max-width:991px) {
                padding-top: 24px;
            }

            a {
                margin: 0 10px 0 0;
            }
        }

        .letsTalk {
            display: flex;
            flex-direction: column;

            b {
                font-weight: 600;
                font-size: 18px;
                line-height: 160%;
                padding-bottom: 20px;

            }

            a {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 28px;
                color: #fff;
                text-decoration: none;

                &:hover {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            padding-top: 11px;
        }
    }

    .headOffice {
        b {
            @media(max-width:991px) {
                font-weight: 600;
            }
        }

    }

    .copyRight {
        border-top: 1px solid #4A4A4A;
        display: block;
        text-align: center;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 160%;
        opacity: 0.78;
        padding: 30px 0;
        @media(max-width:991px) {
            text-align: left;
        }
    }
}